export enum EventName {
  /* PATHS */
  dashboard = "dashboard",
  catalog = "catalog",
  trainings = "trainings",
  documents = "documents",
  groups = "groups",

  /* EVENTS */
  CatalogTrainingDetail = "catalog-training-detail",
  LoginFailure = "login-failure",
  DocumentDownload = "document-download",
  DocumentRead = "document-read",
  DashboardTrainingStart = "dashboard-training-start",
  TrainingStart = "training-start",
}

export interface AnalyticsModel {
  account: {
    id: string;
    is_in_trial: boolean;
    name: string;
    plan: string;
    plan_limit: number;
    subdomain: string;
    trial_end_date: string | null;
  };
  visitor: {
    authenticated: boolean;
    created_at: string;
    email: string;
    full_name: string;
    id: string;
    roles: string[];
    user_platform_language: string;
    username: string;
  };
}
